import React, { RefObject, useEffect, useState } from 'react';

import { StyledLoader } from '@/components/lib';

import { getAppStorage } from '@/helpers/utils/localStorage';
import {
  StepSchema,
  AnnotationType,
  InteractionSchema,
  retrieveInteraction,
  InputPropertiesSchema,
  PropertyDefinitionSchema,
  ApplicationVersionSchema,
  ReadPropertyConfigSchema,
  InteractionSchemaLlmProperties,
  InteractionSchemaOutputProperties,
  getStepsForInteractionApiV1ApplicationVersionsAppVersionIdInteractionsUserInteractionIdStepsGet
} from '@/helpers/services/api';

import { NoSampleDetailsDialogComparisonInteraction } from './NoSampleDetailsDialogComparisonInteraction/NoSampleDetailsDialogComparisonInteraction';
import { SampleDetailsDialogComparisonInteractionContent } from './SampleDetailsDialogComparisonInteractionContent/SampleDetailsDialogComparisonInteractionContent';
import {
  SampleDetailsBodyProperties,
  SamplesDetailsBodyPropertyType
} from '../../SampleDetailsDialogBody/SamplesDetailsBodyItem/SampleDetailsBodyProperties/SampleDetailsBodyProperties';

import { StyledContainer } from './SampleDetailsDialogComparisonInteraction.styles';

import { constants } from '../sampleDetailsDialogComparison.constants';

interface SampleDetailsDialogComparisonInteractionProps {
  metricType?: string;
  mainSelectedTag: string;
  interactionToUseId?: string;
  isVersionComparison?: boolean;
  noInteractionToCompare?: boolean;
  versions?: { name: string; id: number }[];
  versionCompareInteraction: InteractionSchema;
  differentByPropData?: ReadPropertyConfigSchema;
  scrollRef: React.RefObject<HTMLDivElement | null>;
  comparisonProps: SamplesDetailsBodyPropertyType[];
  comparisonContent: { base: string; diff: string; show: boolean };
  initialVersion: { name?: string; id?: number; user_interaction_id?: string };
  selectedVersion: string;
  handleCloseComparison?: () => void;
  handleScroll: (ref: RefObject<HTMLDivElement | null>) => void;
  getPropertyInfo?: (name: string) => PropertyDefinitionSchema;
  setMainSelectedTag: React.Dispatch<React.SetStateAction<string>>;
  handleAddProperty?: (item: SamplesDetailsBodyPropertyType) => void;
  setSelectedComparisonVersionId?: (value: number | undefined) => void;
  handleDeleteProperty?: (item: SamplesDetailsBodyPropertyType) => void;
  setAllPropsArr?: React.Dispatch<React.SetStateAction<SamplesDetailsBodyPropertyType[]>>;
  setSecondSelectedVersion?: React.Dispatch<React.SetStateAction<ApplicationVersionSchema | undefined>>;
  setComparisonContent: React.Dispatch<React.SetStateAction<{ base: string; diff: string; show: boolean }>>;
  updateAnnotation?: (
    interactionId: string,
    annotation: string,
    reason: string,
    isEstimated?: boolean,
    deleteAnnotation?: boolean,
    versionId?: number
  ) => void;
}

const { keys } = constants;

export const SampleDetailsDialogComparisonInteraction = (props: SampleDetailsDialogComparisonInteractionProps) => {
  const {
    versions,
    scrollRef,
    initialVersion,
    comparisonProps,
    selectedVersion,
    comparisonContent,
    interactionToUseId,
    noInteractionToCompare,
    isVersionComparison,
    versionCompareInteraction,
    differentByPropData,
    mainSelectedTag,
    metricType,
    handleScroll,
    setAllPropsArr,
    getPropertyInfo,
    updateAnnotation,
    handleAddProperty,
    setMainSelectedTag,
    handleDeleteProperty,
    setComparisonContent,
    setSecondSelectedVersion,
    setSelectedComparisonVersionId
  } = props;

  const { appId, type } = getAppStorage();

  const [steps, setSteps] = useState<StepSchema[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [interaction, setInteraction] = useState<InteractionSchema>();
  const [selectedTag, setSelectedTag] = useState<string>(keys.output);

  const isSelectable = !!versions?.length;
  const isShowInteraction = !!interaction?.user_interaction_id && !noInteractionToCompare;

  const stepData = Array.isArray(steps) && steps?.filter(s => s?.id === selectedTag)[0];
  const selectedVersionId = Array?.isArray(versions)
    ? versions?.filter(v => v?.name === selectedVersion)[0]?.id
    : initialVersion?.id;

  const checkSectionData = (value: string) => {
    switch (value) {
      case keys.input:
        return { input: interaction?.input?.data, output: '' };

      case keys.output:
        return { input: '', output: interaction?.output?.data };

      case keys.expectedOutput:
        return { input: '', output: interaction?.expected_output?.data };

      case keys.infoRetrieval:
        return {
          input: '',
          output: Array?.isArray(interaction?.information_retrieval)
            ? interaction?.information_retrieval?.map(item => item?.data)?.join('\n')
            : ''
        };

      case keys.history:
        return {
          input: '',
          output: Array?.isArray(interaction?.history) ? interaction?.history?.map(item => item?.data)?.join('\n') : ''
        };

      case keys.fullPrompt:
        return { input: '', output: interaction?.prompt?.data };

      default:
        return stepData ? { input: stepData?.input, output: stepData?.output } : {};
    }
  };

  const handleRetrieveInteraction = async () => {
    if (interactionToUseId) {
      setIsLoading(true);

      await getStepsForInteractionApiV1ApplicationVersionsAppVersionIdInteractionsUserInteractionIdStepsGet(
        Number(selectedVersionId),
        interactionToUseId
      ).then(res => (Array.isArray(res) ? setSteps(res) : setSteps([])));

      if (isSelectable) {
        setTimeout(async () => {
          await retrieveInteraction(Number(selectedVersionId), interactionToUseId).then(res => {
            res?.user_interaction_id ? setInteraction(res) : setInteraction(undefined);
            setTimeout(() => setIsLoading(false), 100);
          });
        }, 200);
      } else {
        await retrieveInteraction(Number(selectedVersionId), interactionToUseId).then(res => {
          res?.user_interaction_id ? setInteraction(res) : setInteraction(undefined);
          setTimeout(() => setIsLoading(false), 200);
        });
      }
      setSelectedTag(keys.output);
    }
  };

  const handleBuildCurProperties = (
    propertiesList:
      | InputPropertiesSchema
      | InteractionSchemaLlmProperties
      | InteractionSchemaOutputProperties
      | undefined,
    type: string
  ) => {
    return propertiesList
      ? Object.entries(propertiesList).map(([key, value]) => ({
          name: key,
          value,
          type: type as 'input' | 'output' | 'custom' | 'llm'
        }))
      : [];
  };

  const dataToShow = checkSectionData(selectedTag);

  const allProps: SamplesDetailsBodyPropertyType[] = [
    ...handleBuildCurProperties(interaction?.input_properties, 'input'),
    ...handleBuildCurProperties(interaction?.output_properties, 'output'),
    ...handleBuildCurProperties(interaction?.llm_properties, 'llm'),
    ...handleBuildCurProperties(interaction?.custom_properties, 'custom'),
    ...(type === 'PENTEST' ? handleBuildCurProperties(interaction?.garak_properties, 'garak') : [])
  ];

  const curProps = Array.isArray(comparisonProps)
    ? comparisonProps.reduce((acc, el) => {
        const findCurElemInAllProp = allProps.find(elem => elem.name === el.name && elem.type === el.type);

        return [...acc, ...(findCurElemInAllProp ? [findCurElemInAllProp] : [{ ...el, value: 'n/a' }])];
      }, [] as SamplesDetailsBodyPropertyType[])
    : ([] as SamplesDetailsBodyPropertyType[]);

  const allPropsList = allProps.filter(el => !curProps.find(elem => elem.name === el.name && elem.type === el.type));

  const updateCurAnnotation = (
    interactionId: string,
    annotation: string,
    reason: string,
    isEstimated?: boolean,
    deleteAnnotation?: boolean
  ) => {
    updateAnnotation &&
      updateAnnotation(
        interactionId,
        annotation,
        reason,
        isEstimated,
        deleteAnnotation,
        isVersionComparison ? interaction?.application_version_id : undefined
      );
    interaction &&
      setInteraction({
        ...interaction,
        annotation: deleteAnnotation
          ? null
          : {
              updated_at: interaction?.annotation?.updated_at ?? '',
              created_at: interaction?.annotation?.created_at ?? '',
              is_estimated: isEstimated ? true : false,
              value: annotation as AnnotationType,
              reason: reason as string,
              origin: interaction?.annotation?.origin ?? null,
              similarity_reason: interaction?.annotation?.similarity_reason ?? null
            }
      });
  };

  useEffect(() => {
    if (selectedVersionId && interactionToUseId) {
      const versionAllData = Array.isArray(versions) ? versions?.find(el => el.id === selectedVersionId) : undefined;
      if (versionAllData) {
        setSecondSelectedVersion && setSecondSelectedVersion(versionAllData as ApplicationVersionSchema);
      }
      if (!isVersionComparison) {
        handleRetrieveInteraction();
      }
      setSelectedComparisonVersionId && setSelectedComparisonVersionId(selectedVersionId);
    }
  }, [selectedVersionId]);

  useEffect(() => {
    if (setAllPropsArr && interaction && !isSelectable) {
      setAllPropsArr(allProps);
    }
    if (mainSelectedTag !== selectedTag) {
      setMainSelectedTag(keys.output);
    }
  }, [interaction]);

  useEffect(() => {
    if (isVersionComparison) {
      setInteraction(versionCompareInteraction);
    }
  }, [versionCompareInteraction]);

  useEffect(() => {
    if (
      mainSelectedTag !== 'step' &&
      selectedTag !== mainSelectedTag &&
      (!!checkSectionData(mainSelectedTag)?.output || !!checkSectionData(mainSelectedTag)?.input)
    ) {
      setSelectedTag(mainSelectedTag);
    }
  }, [mainSelectedTag]);

  return (
    <StyledContainer
      first={!isSelectable ? 'true' : 'false'}
      is_big_container={!isVersionComparison ? 'true' : 'false'}
    >
      {isLoading ? (
        <StyledLoader />
      ) : !isLoading && !isShowInteraction ? (
        <NoSampleDetailsDialogComparisonInteraction />
      ) : (
        isShowInteraction && (
          <>
            <SampleDetailsDialogComparisonInteractionContent
              metricType={metricType}
              steps={steps}
              scrollRef={scrollRef}
              dataToShow={dataToShow}
              selectedTag={selectedTag}
              interaction={interaction}
              isSelectable={isSelectable}
              initialVersion={initialVersion}
              selectedVersion={selectedVersion}
              comparisonContent={comparisonContent}
              differentByPropData={differentByPropData}
              allPropsList={[...allPropsList, ...curProps]}
              comparedVersionData={{
                versionId: interaction?.application_version_id,
                interactionId: interaction?.user_interaction_id
              }}
              versionCompareInteraction={!!versionCompareInteraction}
              handleScroll={handleScroll}
              setSelectedTag={setSelectedTag}
              checkSectionData={checkSectionData}
              updateAnnotation={updateCurAnnotation}
              setMainSelectedTag={setMainSelectedTag}
              setComparisonContent={setComparisonContent}
            />
            <SampleDetailsBodyProperties
              appId={appId}
              isComparison={true}
              comparisonProps={curProps}
              allPropsList={allPropsList}
              key={interaction?.user_interaction_id}
              llmReasons={interaction?.llm_properties_reasons}
              properties={{
                input: interaction?.input_properties,
                output: interaction?.output_properties,
                custom: interaction?.custom_properties,
                llm: interaction?.llm_properties,
                garak: interaction?.garak_properties
              }}
              getPropertyInfo={getPropertyInfo}
              handleAddProperty={handleAddProperty}
              handleDeleteProperty={handleDeleteProperty}
            />
          </>
        )
      )}
    </StyledContainer>
  );
};
