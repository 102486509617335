export const constants = {
  input: 'Input:',
  version: 'Version',
  outPut: 'Interaction Output:',
  searchVersion: 'Search Version..',
  selectedProps: 'Selected properties',
  noVersionToCompare: 'No second version to compare',
  noVersionDescription: 'No version description has been provided',
  diffTooltip: (showDiff: boolean) => (showDiff ? 'Remove Diff Marking' : 'Mark Diff'),
  contentHeight: '280px',
  scoreWithEst: 'Score w. Est',
  score: 'Score',
  numberOfInteractionText: (allInteractionNumber: number, overlappingInteractionNumber: number) =>
    allInteractionNumber && allInteractionNumber
      ? `Showing ${overlappingInteractionNumber} overlapping interaction out of ${allInteractionNumber} interactions in environment`
      : '',
  keys: {
    input: 'Input',
    output: 'Output',
    history: 'History',
    fullPrompt: 'Full Prompt',
    expectedOutput: 'Expected Output',
    infoRetrieval: 'Information Retrieval'
  },
  documentTitle: (number: number) => `Document #${number}`,
  propsLimit: 4,
  similarityContent: {
    tooltip: (deepchecks?: number, blue_n1?: number, rouge_n1?: number) =>
      `Deepchecks Similarity: ${Number(deepchecks)?.toFixed(2)}\nBLEU N1: ${Number(blue_n1)?.toFixed(2)}\nRouge N1: ${Number(rouge_n1)?.toFixed(2)}`,
    similarityLabel: 'Similarity Score:',
    content: (input?: string | null, output?: string | null) =>
      input && output ? `Input:\n ${input} \n\n Output:\n ${output}` : input ? input : `${output}`
  }
};
